export const COUNTRIES = [
    'MALAYSIA',
    "TAIWAN",
    "HONGKONG",
    "CHILE",
    "MEXICO",
    "PHILIPPINES",
    "SINGAPORE",
    "THAILAND",
    "VIETNAM",
    "TURKEY",
    "POLAND",
    "CHINA",
    'OTHER',
]

export const VISA_TYPES = [
    // "Visitor",
    // "Student",
    // "Work",
    // "LimitedPurpose",
    // "WorkingHolidayScheme",
    // "Residence"
    {
        label: "Visitor",
        value: "Visitor",
    }, {
        label: "Student",
        value: "Student",
    }, {
        label: "Work",
        value: "Work",
    }, {
        label: "Limited Purpose",
        value: "LimitedPurpose",
    }, {
        label: "Working Holiday Scheme",
        value: "WorkingHolidayScheme",
    }, {
        label: "Residence",
        value: "Residence",
    }
]

export const ID_TYPES = [
    {
        label: "Driver License",
        value: "DL",
    }, {
        label: 'National ID',
        value: 'NI',
    }, {
        label: 'Birth Certificate',
        value: 'BC',
    }
]